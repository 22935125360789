// 此文件是通过脚本生成的，直接编辑无效！！！

// 不需要导航框架的页面路径
export const noFrames = [
    '/login',
    '/login1',
];

// 不需要登录就可以访问的页面路径
export const noAuths = [
    '/login',
    '/login1',
];

// 需要keep alive 页面
export const keepAlives = [
    {
        path: '/',
        keepAlive: false,
    },
    {
        path: '/',
        keepAlive: false,
    },
    {
        path: '/iframe_page_/:src',
        keepAlive: true,
    },
    {
        path: '/login',
        keepAlive: false,
    },
    {
        path: '/login1',
        keepAlive: false,
    },
    {
        path: '/AboutUs',
        keepAlive: false,
    },
    {
        path: '/AdminList',
        keepAlive: false,
    },
    {
        path: '/RoleList',
        keepAlive: false,
    },
    {
        path: '/NewActivities',
        keepAlive: false,
    },
    {
        path: '/NewDynamicPublished',
        keepAlive: false,
    },
    {
        path: '/NewDynamicWaiting',
        keepAlive: false,
    },
    {
        path: '/ServiceContactUsPublished',
        keepAlive: false,
    },
    {
        path: '/ServiceContactUsWaiting',
        keepAlive: false,
    },
    {
        path: '/ServiceSwipe',
        keepAlive: false,
    },
    {
        path: '/aroundAd',
        keepAlive: false,
    },
    {
        path: '/ArticleStatistics',
        keepAlive: false,
    },
    {
        path: '/Clock',
        keepAlive: false,
    },
    {
        path: '/ContactUsPublished',
        keepAlive: false,
    },
    {
        path: '/ContactUsWaiting',
        keepAlive: false,
    },
    {
        path: '/detailSet',
        keepAlive: false,
    },
    {
        path: '/EmergencyRescueArticlePublished',
        keepAlive: false,
    },
    {
        path: '/EmergencyRescueArticleWaiting',
        keepAlive: false,
    },
    {
        path: '/EmergencyRescueChild',
        keepAlive: false,
    },
    {
        path: '/EmergencyRescueSwipe',
        keepAlive: false,
    },
    {
        path: '/exhibitor',
        keepAlive: false,
    },
    {
        path: '/exhibitionComplex',
        keepAlive: false,
    },
    {
        path: '/exhibitionComplex2',
        keepAlive: false,
    },
    {
        path: '/ExpertColumnArticlePublished',
        keepAlive: false,
    },
    {
        path: '/ExpertColumnArticleWaiting',
        keepAlive: false,
    },
    {
        path: '/ExpertColumnChild',
        keepAlive: false,
    },
    {
        path: '/ExpertColumnSwipe',
        keepAlive: false,
    },
    {
        path: '/ExpertTypeList',
        keepAlive: false,
    },
    {
        path: '/HomePageArticlePublished',
        keepAlive: false,
    },
    {
        path: '/HomePageArticleWaiting',
        keepAlive: false,
    },
    {
        path: '/HomePageChild',
        keepAlive: false,
    },
    {
        path: '/HomePageSwipe',
        keepAlive: false,
    },
    {
        path: '/ArticleSource',
        keepAlive: false,
    },
    {
        path: '/InstantNews',
        keepAlive: false,
    },
    {
        path: '/MemberChannelArticlePublished',
        keepAlive: false,
    },
    {
        path: '/MemberChannelArticleWaiting',
        keepAlive: false,
    },
    {
        path: '/MemberChannelChild',
        keepAlive: false,
    },
    {
        path: '/MemberChannelSwipe',
        keepAlive: false,
    },
    {
        path: '/MessageList',
        keepAlive: false,
    },
    {
        path: '/MHNewsArticlePublished',
        keepAlive: false,
    },
    {
        path: '/MHNewsArticleWaiting',
        keepAlive: false,
    },
    {
        path: '/MHNewsChild',
        keepAlive: false,
    },
    {
        path: '/MHNewsSwipe',
        keepAlive: false,
    },
    {
        path: '/NoticeList',
        keepAlive: false,
    },
    {
        path: '/ReportList',
        keepAlive: false,
    },
    {
        path: '/UserReport',
        keepAlive: false,
    },
    {
        path: '/UserReportList',
        keepAlive: false,
    },
    {
        path: '/SmartSecurityArticlePublished',
        keepAlive: false,
    },
    {
        path: '/SmartSecurityArticleWaiting',
        keepAlive: false,
    },
    {
        path: '/SmartSecurityChild',
        keepAlive: false,
    },
    {
        path: '/SmartSecuritySwipe',
        keepAlive: false,
    },
    {
        path: '/MHNewsSubjectArticlePublished',
        keepAlive: false,
    },
    {
        path: '/MHNewsSubjectArticleWaiting',
        keepAlive: false,
    },
    {
        path: '/MHNewsSubjectSwipe',
        keepAlive: false,
    },
    {
        path: '/MHSubjectList',
        keepAlive: false,
    },
    {
        path: '/SubjectTitleBarList',
        keepAlive: false,
    },
    {
        path: '/MHNewsSubjectSwipe2',
        keepAlive: false,
    },
    {
        path: '/SecuritySubjectArticlePublished',
        keepAlive: false,
    },
    {
        path: '/SecuritySubjectArticleWaiting',
        keepAlive: false,
    },
    {
        path: '/SecuritySubjectList',
        keepAlive: false,
    },
    {
        path: '/SecuritySubjectTitleBarList',
        keepAlive: false,
    },
    {
        path: '/SecuritySubjectSwipe',
        keepAlive: false,
    },
    {
        path: '/TradeShowsArticlePublished',
        keepAlive: false,
    },
    {
        path: '/TradeShowsArticleWaiting',
        keepAlive: false,
    },
    {
        path: '/TradeShowsChild',
        keepAlive: false,
    },
    {
        path: '/TradeShowsSwipe',
        keepAlive: false,
    },
    {
        path: '/ZCdecodeArticlePublished',
        keepAlive: false,
    },
    {
        path: '/ZCdecodeArticleWaiting',
        keepAlive: false,
    },
    {
        path: '/ZCdecodeChild',
        keepAlive: false,
    },
    {
        path: '/ZCdecodeSwipe',
        keepAlive: false,
    },
    {
        path: '/ArticleComment:article',
        keepAlive: false,
    },
    {
        path: '/ArticleLib',
        keepAlive: false,
    },
    {
        path: '/ArticleWatingAudit',
        keepAlive: false,
    },
    {
        path: '/PublishedArticleList',
        keepAlive: false,
    },
    {
        path: '/CourseBill',
        keepAlive: false,
    },
    {
        path: '/VipBill',
        keepAlive: false,
    },
    {
        path: '/FriendlyLink',
        keepAlive: false,
    },
    {
        path: '/MemberType',
        keepAlive: false,
    },
    {
        path: '/MemberPermission',
        keepAlive: false,
    },
    {
        path: '/SwipeAdvertise',
        keepAlive: false,
    },
    {
        path: '/TitleBar',
        keepAlive: false,
    },
    {
        path: '/VipActivity',
        keepAlive: false,
    },
    {
        path: '/AddCourse',
        keepAlive: true,
    },
    {
        path: '/CourseList',
        keepAlive: false,
    },
    {
        path: '/VideoList',
        keepAlive: false,
    },
    {
        path: '/ShopApply',
        keepAlive: true,
    },
    {
        path: '/ExpertsList',
        keepAlive: false,
    },
    {
        path: '/setFirmTab',
        keepAlive: false,
    },
    {
        path: '/UserList',
        keepAlive: false,
    },
    {
        path: '/VipList',
        keepAlive: false,
    },
    {
        path: '/WaitingMember',
        keepAlive: false,
    },
];

// 页面路由配置
export default [
    {
        path: '/example/ajax',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/examples/ajax/index.jsx'),
    },
    {
        path: '/example/customer-header',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/examples/customer-header/index.jsx'),
    },
    {
        path: '/',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/home/Example.jsx'),
    },
    {
        path: '/',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/home/index.jsx'),
    },
    {
        path: '/iframe_page_/:src',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/iframe/index.jsx'),
    },
    {
        path: '/login',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/login/index.jsx'),
    },
    {
        path: '/login1',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/login1/index.jsx'),
    },
    {
        path: '/menu-permission',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/menu-permission/index.jsx'),
    },
    {
        path: '/roles',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/roles/index.jsx'),
    },
    {
        path: '/settings',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/setting/index.jsx'),
    },
    {
        path: '/users',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/users/index.jsx'),
    },
    {
        path: '/AboutUs',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/aboutus/AboutUs.jsx'),
    },
    {
        path: '/AdminList',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/adminmanager/adminlist/AdminList.jsx'),
    },
    {
        path: '/RoleList',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/adminmanager/rolelist/RoleList.jsx'),
    },
    {
        path: '/EditArticle',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/advertiseservice/EditArticle.jsx'),
    },
    {
        path: '/NewActivities',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/advertiseservice/newactivities/NewActivities.jsx'),
    },
    {
        path: '/NewDynamicPublished',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/advertiseservice/newdynamic/NewDynamicPublished.jsx'),
    },
    {
        path: '/NewDynamicWaiting',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/advertiseservice/newdynamic/NewDynamicWaiting.jsx'),
    },
    {
        path: '/ServiceContactUsPublished',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/advertiseservice/servicecontactus/ServiceContactUsPublished.jsx'),
    },
    {
        path: '/ServiceContactUsWaiting',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/advertiseservice/servicecontactus/ServiceContactUsWaiting.jsx'),
    },
    {
        path: '/ServiceSwipe',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/advertiseservice/serviceswipe/ServiceSwipe.jsx'),
    },
    {
        path: '/aroundAd',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/aroundAd/AroundAd.jsx'),
    },
    {
        path: '/ArticleStatistics',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/articleStatistics/articleStatistics.jsx'),
    },
    {
        path: '/Clock',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/clock/clock.jsx'),
    },
    {
        path: '/ContactUsPublished',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/contactus/ContactUsPublished.jsx'),
    },
    {
        path: '/ContactUsWaiting',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/contactus/ContactUsWaiting.jsx'),
    },
    {
        path: '/detailSet',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/detailSet/HomePageSwipe.jsx'),
    },
    {
        path: '/EmergencyRescueArticlePublished',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/emergencyrescue/article/SmartSecurityArticlePublished.jsx'),
    },
    {
        path: '/EmergencyRescueArticleWaiting',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/emergencyrescue/article/SmartSecurityArticleWaiting.jsx'),
    },
    {
        path: '/EmergencyRescueChild',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/emergencyrescue/smartsecuritychild/SmartSecurityChild.jsx'),
    },
    {
        path: '/EmergencyRescueSwipe',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/emergencyrescue/smartsecurityswipe/SmartSecuritySwipe.jsx'),
    },
    {
        path: '/exhibitor',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/exhibition/exhibitor/index.jsx'),
    },
    {
        path: '/exhibitionComplex',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/exhibition/introduction/index.jsx'),
    },
    {
        path: '/exhibitionComplex2',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/exhibition/introduction/index2.jsx'),
    },
    {
        path: '/ExpertColumnArticlePublished',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/expertcolumn/article/ExpertColumnArticlePublished.jsx'),
    },
    {
        path: '/ExpertColumnArticleWaiting',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/expertcolumn/article/ExpertColumnArticleWaiting.jsx'),
    },
    {
        path: '/ExpertColumnChild',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/expertcolumn/expertcolumnchild/ExpertColumnChild.jsx'),
    },
    {
        path: '/ExpertColumnSwipe',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/expertcolumn/expertcolumnswipe/ExpertColumnSwipe.jsx'),
    },
    {
        path: '/ExpertTypeList',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/expertcolumn/experttype/index.jsx'),
    },
    {
        path: '/HomePageArticlePublished',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/homepage/article/HomePageArticlePublished.jsx'),
    },
    {
        path: '/HomePageArticleWaiting',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/homepage/article/HomePageArticleWaiting.jsx'),
    },
    {
        path: '/HomePageChild',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/homepage/homepagechild/HomePageChild.jsx'),
    },
    {
        path: '/HomePageSwipe',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/homepage/homepageswipe/HomePageSwipe.jsx'),
    },
    {
        path: '/ArticleSource',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/InstantNews/ArticleSource.jsx'),
    },
    {
        path: '/InstantNews',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/InstantNews/InstantNews.jsx'),
    },
    {
        path: '/MemberChannelArticlePublished',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/memberchannel/article/MemberChannelArticlePublished.jsx'),
    },
    {
        path: '/MemberChannelArticleWaiting',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/memberchannel/article/MemberChannelArticleWaiting.jsx'),
    },
    {
        path: '/MemberChannelChild',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/memberchannel/memberchannelchild/MemberChannelChild.jsx'),
    },
    {
        path: '/MemberChannelSwipe',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/memberchannel/memberchannelswipe/MemberChannelSwipe.jsx'),
    },
    {
        path: '/MessageList',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/message/MessageList.jsx'),
    },
    {
        path: '/MHNewsArticlePublished',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/mhnews/article/MHNewsArticlePublished.jsx'),
    },
    {
        path: '/MHNewsArticleWaiting',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/mhnews/article/MHNewsArticleWaiting.jsx'),
    },
    {
        path: '/MHNewsChild',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/mhnews/mhnewschild/MHNewsChild.jsx'),
    },
    {
        path: '/MHNewsSwipe',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/mhnews/mhnewsswipe/MHNewsSwipe.jsx'),
    },
    {
        path: '/NoticeList',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/notice/NoticeList.jsx'),
    },
    {
        path: '/ReportList',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/report/ReportList.jsx'),
    },
    {
        path: '/UserReport',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/report/UserReport.jsx'),
    },
    {
        path: '/UserReportList',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/report/UserReportList.jsx'),
    },
    {
        path: '/SmartSecurityArticlePublished',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/smartsecurity/article/SmartSecurityArticlePublished.jsx'),
    },
    {
        path: '/SmartSecurityArticleWaiting',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/smartsecurity/article/SmartSecurityArticleWaiting.jsx'),
    },
    {
        path: '/SmartSecurityChild',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/smartsecurity/smartsecuritychild/SmartSecurityChild.jsx'),
    },
    {
        path: '/SmartSecuritySwipe',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/smartsecurity/smartsecurityswipe/SmartSecuritySwipe.jsx'),
    },
    {
        path: '/MHNewsSubjectArticlePublished',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/subjectmanager/mhnewssubject/article/MHNewsSubjectArticlePublished.jsx'),
    },
    {
        path: '/MHNewsSubjectArticleWaiting',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/subjectmanager/mhnewssubject/article/MHNewsSubjectArticleWaiting.jsx'),
    },
    {
        path: '/MHNewsSubjectSwipe',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/subjectmanager/mhnewssubject/mhnewssubjectswipe/MHNewsSubjectSwipe.jsx'),
    },
    {
        path: '/MHSubjectList',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/subjectmanager/mhnewssubject/subjectlist/SubjectList.jsx'),
    },
    {
        path: '/SubjectTitleBarList',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/subjectmanager/mhnewssubject/subjectlist/SubjectTitleBarList.jsx'),
    },
    {
        path: '/MHNewsSubjectSwipe2',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/subjectmanager/mhnewssubject/swipe/MHNewsSubjectSwipe2.jsx'),
    },
    {
        path: '/SecuritySubjectArticlePublished',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/subjectmanager/securitysubject/article/SecuritySubjectArticlePublished.jsx'),
    },
    {
        path: '/SecuritySubjectArticleWaiting',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/subjectmanager/securitysubject/article/SecuritySubjectArticleWaiting.jsx'),
    },
    {
        path: '/SecuritySubjectList',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/subjectmanager/securitysubject/subjectlist/SecuritySubjectList.jsx'),
    },
    {
        path: '/SecuritySubjectTitleBarList',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/subjectmanager/securitysubject/subjectlist/SecuritySubjectTitleBarList.jsx'),
    },
    {
        path: '/SecuritySubjectSwipe',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/subjectmanager/securitysubject/swipe/SecuritySubjectSwipe.jsx'),
    },
    {
        path: '/TradeShowsArticlePublished',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/tradeshows/article/TradeShowsArticlePublished.jsx'),
    },
    {
        path: '/TradeShowsArticleWaiting',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/tradeshows/article/TradeShowsArticleWaiting.jsx'),
    },
    {
        path: '/TradeShowsChild',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/tradeshows/tradeshowschild/TradeShowsChild.jsx'),
    },
    {
        path: '/TradeShowsSwipe',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/tradeshows/tradeshowsswipe/TradeShowsSwipe.jsx'),
    },
    {
        path: '/ZCdecodeArticlePublished',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/ZCdecode/article/ZCdecodeArticlePublished.jsx'),
    },
    {
        path: '/ZCdecodeArticleWaiting',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/ZCdecode/article/ZCdecodeArticleWaiting.jsx'),
    },
    {
        path: '/ZCdecodeChild',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/ZCdecode/ZCdecodechild/ZCdecodeChild.jsx'),
    },
    {
        path: '/ZCdecodeSwipe',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/viewpage/ZCdecode/ZCdecodeswipe/ZCdecodeSwipe.jsx'),
    },
    {
        path: '/ArticleComment:article',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/Article/articlecomment/index.jsx'),
    },
    {
        path: '/AddArticle:id',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/Article/ArticleLib/AddArticle.jsx'),
    },
    {
        path: '/ArticleLib',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/Article/ArticleLib/index.jsx'),
    },
    {
        path: '/ArticleWatingAudit',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/Article/articlewatingaudit/index.jsx'),
    },
    {
        path: '/PublishedArticleList',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/Article/PublishedArticle/index.jsx'),
    },
    {
        path: '/CourseBill',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/billmanager/coursebill/index.jsx'),
    },
    {
        path: '/VipBill',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/billmanager/vipbill/index.jsx'),
    },
    {
        path: '/FriendlyLink',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/conf/FriendlyLink/index.jsx'),
    },
    {
        path: '/MemberType',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/conf/membertype/index.jsx'),
    },
    {
        path: '/MemberPermission',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/conf/membertype/MemberPermissions.jsx'),
    },
    {
        path: '/SwipeAdvertise',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/conf/SwipeAdvertise/index.jsx'),
    },
    {
        path: '/TitleBar',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/conf/TitleBar/index.jsx'),
    },
    {
        path: '/VipActivity',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/conf/VipActivity/VipActivity.jsx'),
    },
    {
        path: '/AddCourse',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/course/addcourse/index.jsx'),
    },
    {
        path: '/CourseList',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/course/courselist/index.jsx'),
    },
    {
        path: '/VideoList',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/course/courselist/VideoList.jsx'),
    },
    {
        path: '/shop-OneCategory',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/shop/Category/OneCategory/index.jsx'),
    },
    {
        path: '/ShopApply',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/shop/ShopApply/index.jsx'),
    },
    {
        path: '/shop-shoplist',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/shop/shopList/index.jsx'),
    },
    {
        path: '/ExpertsList',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/usermanager/expertslist/index.jsx'),
    },
    {
        path: '/setFirmTab',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/usermanager/setTab/index.jsx'),
    },
    {
        path: '/UserList',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/usermanager/userlist/index.jsx'),
    },
    {
        path: '/VipList',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/usermanager/viplist/index.jsx'),
    },
    {
        path: '/WaitingMember',
        component: () => import('/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/pages/views/usermanager/waitingmember/index.jsx'),
    },
];
