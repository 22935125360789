// 此文件为生成文件，不要直接编辑
import _data from '/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/models/data.js';
import _menu from '/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/models/menu.js';
import _page from '/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/models/page.js';
import _settings from '/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/models/settings.js';
import _side from '/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/models/side.js';
import _system from '/Users/jadexie/co/Hurricane/Recommend/minhang/code/admin/src/models/system.js';

export const data = _data;
export const menu = _menu;
export const page = _page;
export const settings = _settings;
export const side = _side;
export const system = _system;

