import React, { Component } from 'react';
import { Upload, Button, Icon, notification } from 'antd';
import PropTypes from 'prop-types';
// import { imgPreview } from '@/library/utils/image-utils.js';
import MD5 from 'crypto-js/md5';
import { sxAjax } from '@/commons/ajax';
import { base } from '@/commons/base';
import { matchType } from '@/library/utils/image-utils.js';

/**
 * 上传图片到阿里云oss
 */

export default class AliOssUpload extends Component {
    constructor(props) {
        super(props);
        if (this.props.onUploadRef) {
            this.props.onUploadRef(this)
        }
    }
    static propTypes = {
        UploadCallBack: PropTypes.func,
        folder: PropTypes.string,
    };

    static defaultProps = {
        folder: '',
    }

    state = { OSSData: { host: '' }, ImgFileState: false, update_file: null, file_name: '', file_list: [] };

    componentDidMount() {

        this.init()
    }

    componentWillReceiveProps() {

        let { fileList } = this.props

        if (fileList && fileList.length > 0) {
            console.log(fileList)
            this.setState({ file_list: fileList })
        }
    }

    init = async () => {
        this.GetOSSData(OSSData => {
            if (OSSData === null) {
                return
            }

            this.setState({
                OSSData,
            });
        });
    };

    GetOSSData = (callback) => {
        sxAjax.post('/admin_v2/api/get_osstoken', {}).then((res) => {
            if (res.status === 200) {
                // return {
                //     dir: 'user-dir/',
                //     expire: '1577811661',
                //     host: '//www.mocky.io/v2/5cc8019d300000980a055e76',
                //     accessId: 'c2hhb2RhaG9uZw==',
                //     policy: 'eGl4aWhhaGFrdWt1ZGFkYQ==',
                //     signature: 'ZGFob25nc2hhbw==',
                // };
                callback(res.data);
            } else {
                notification.error({
                    message: '提示！',
                    description: res.msg,
                });
            };
            callback(null)
        }).catch(() => {
            notification.error({
                message: '提示！',
                description: "网络超时！",
            });

        });

    };

    // 检查图片压缩等工作是否准备完毕 生成图片名称
    CheckBeforeUploadingPictures() {
        if (this.state.ImgFileState) {
            notification.warning({
                message: '提示！',
                description: "图片正在压缩中，请稍后提交！",
            });
            return false;
        }

        if (this.state.file_list.length === 0) {
            notification.warning({
                message: '提示！',
                description: "请选择上传图片！",
            });
            return false;
        }

        let file = this.state.file
        let filename = MD5(file.name + file.uid);
        let fileType = file.name.split(".");
        fileType = `.${fileType[fileType.length - 1]}`;
        filename += fileType;
        const ImgkeyName = `${this.props.folder}/${filename}`;
        this.setState({ file_name: ImgkeyName })

        return ImgkeyName
    }

    // 用户选取图片 将图片进行压缩
    onChange(e) {
        this.props.GetImgList(e)
        this.setState({ file_list: e.fileList })
        // if (e.fileList.length !== this.state.file_list.length) {
        //     this.setState({ file_list: e.fileList })
        //     return
        // }
        
        // let file = e.file
        // this.setState({ file: file, ImgFileState: true, file_list: e.fileList })
        // imgPreview(file, (file) => {
        //     this.setState({ update_file: file, ImgFileState: false })
        // })

    }

    beforeUpload = async (file) => {

        // const { OSSData } = this.state;
        // const expire = OSSData.expire * 1000;

        // if (expire < Date.now()) {
        //     await this.init();
        // }
        console.log(file)
       

        return new Promise((resolve, reject) => {
            
            const file_type = matchType(file.name)
            if (file_type === 'video') {
                const isLt20M = file.size / 1024 / 1024 < 500;
                if (!isLt20M) {
                    notification.error({
                        message: '提示！',
                        description: "大小超出限制，请修改后重新上传",
                    });
                    return reject(false)
                }
            }
            

            return resolve(true);
        
          });

    };

    transformFile = async file => {

        // this.setState({ ImgFileState: false })
        // await imgPreview(file, (file) => {

        //     return file;
        // })

        const { OSSData } = this.state;

        const suffix = file.name.slice(file.name.lastIndexOf('.'));
        const filename = MD5(Date.now() + file.name + file.uid) + suffix;
        file.url = OSSData.dir + filename;
        
        this.setState({ file: file, ImgFileState: true })
        
        return file;

    };

    fileList = [] ;
    getExtraData = (file ) => {
        const { OSSData } = this.state;

        return {
            key: file.url,
            OSSAccessKeyId: OSSData.accessid,
            policy: OSSData.policy,
            Signature: OSSData.signature,
        };
    };

    // 下载添加oss前缀
    onDownload = (file) => {

        if (file.url.startsWith('http')) {
            window.open(file.url)
            return file.url
        }

        let url = base.OssDownloadAddress + file.url
        window.open(url)
        return url
    }

    // 预览添加oss前缀
    onPreview = (file) => {
        if (file.url.startsWith('http')) {
            window.open(file.url)
            return file.url
        }
        let url = base.OssAddress + file.url
        window.open(url)
        return url
    }

    render() {
        const { limit, text } = this.props;
        const { OSSData } = this.state;

        return (
            <div>

                <Upload {...this.props} action={OSSData.host} onChange={this.onChange.bind(this)} beforeUpload={this.beforeUpload.bind(this)} transformFile={this.transformFile.bind(this)} data={this.getExtraData.bind(this)} fileList={this.state.file_list} onDownload={this.onDownload.bind(this)} onPreview={this.onPreview} >
                    {
                        limit > this.state.file_list ?
                            <Button  >
                                <Icon type="upload" /> {text}
                            </Button> : null
                    }

                </Upload>


                {/* <Button onClick={this.PutCosImg.bind(this)} >
                    <Icon type="upload" /> 上传
            </Button> */}
            </div>
        );
    }
}
