import SXAjax, { createAjaxHoc } from '@/library/ajax';
import mockUrls from '../mock/url-config';
import handleError from './handle-error';
import handleSuccess from './handle-success';
import { isLogin , getLoginUser } from './index';
import { TAMethodADD, TAMethod } from '@/library/utils/des'
import { tools } from '@/library/utils/tools';

const ta_ = tools.DMT()
const ta_a = ta_.a
const ta_b = ta_.b

/**
 * 判断请求是否是mock
 * @param url
 * @returns {boolean|*}
 */
export function isMock(url /* url, data, method, options */) {
    if(url){
        return mockUrls.indexOf(url) > -1 || url.startsWith('/mock');

    }
}

/**
 * ajax工具，含有errorTip 和 successTip
 * @type {SXAjax}
 */
export const sxAjax = new SXAjax({
    onShowErrorTip: (error, errorTip) => handleError({ error, errorTip }),
    onShowSuccessTip: (response, successTip) => handleSuccess({ successTip }),
    isMock,
});

// 默认配置
// sxAjax.defaults.baseURL = '/api';
// sxAjax.defaults.baseURL = 'http://152.136.17.68/mh_api';
// 正式
sxAjax.defaults.baseURL = 'http://admin.cacps.cn/mh_api';
// 测试
// sxAjax.defaults.baseURL = 'http://152.136.17.68:9090';
sxAjax.defaults.timeout = 1000 * 60;
sxAjax.mockDefaults.baseURL = '/';

/**
 * ajax高阶组件
 */
export const ajaxHoc = createAjaxHoc(sxAjax);

/**
 * ajax工具，不含有 errorTip和successTip
 * @type {SXAjax}
 */
export const ajax = new SXAjax({
    isMock,
});

// 默认配置
// ajax.defaults.baseURL = '/admin';
ajax.defaults.timeout = 1000 * 5;

[ajax.instance, sxAjax.instance].forEach(instance => {
    instance.interceptors.request.use(cfg => {
        // Do something before request is sent
        if (cfg.url.includes('admin')) {
            
            if (isLogin()) {
                // let headers = Object.assign({ "AuthToken": getLoginUser().token} ,cfg.headers)
                // cfg.headers = headers
                
                cfg.headers = {
                    ...{ "AuthToken": getLoginUser().token }
                }
            }

            // 加密
            cfg.data = TAMethodADD(cfg.data, ta_a, ta_b)
        }

        return cfg;
    }, error => {
        // Do something with request error
        return Promise.reject(error);
    });
    instance.interceptors.response.use(res => {
        if (res.data.status === 200 && res.data.data && res.config.url.includes("admin")) {
            res.data.data = JSON.parse(TAMethod(res.data.data, ta_a, ta_b))
        }

        // if (res.data.status === 401) {
        //     let error = res
        //     error.status = res.data.status
        //     handleError({ error: { response: error } })
        // }
        console.log("<  ↓ ↓ ↓ 接口响应结果 ↓ ↓ ↓  >", res.data);
        return res
    }, error => {

        // // const { response: { status, statusText, data: { msg = '服务器发生错误' } }} = error
        // const { response } = error
        // if (error === undefined || error.code === 'ECONNABORTED') {
        //     response.staus = 504
        //     response.statusText = "服务请求超时"
        // }

        // handleError({ error: { response } })
        return Promise.reject(error);
    });
});


/**
 * mockjs使用的axios实例
 */
export const mockInstance = ajax.mockInstance = sxAjax.mockInstance;

