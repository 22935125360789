/*
* 菜单数据 返回Promise各式，支持前端硬编码、异步获取菜单数据
* */

import { getLoginUser } from '@/commons';


export default function getMenus(userId) {
    // TODO 根据userId获取菜单数据 或在此文件中前端硬编码菜单
    if (getLoginUser().permissions === 100) {
        return Promise.resolve([

            //文章库
            { key: 'ArticleLib', local: 'ArticleLib', text: '文章库', icon: 'bars', path: '/ArticleLib', order: 2000 },
        ]);
    } else {
        return Promise.resolve([
            // {key: '1', text: '多级菜单', icon: 'align-left'},
            // {key: '1-1', parentKey: '1', text: 'level-1-1', path: '/user-center', icon: 'align-left'},
            // {key: '1-2', parentKey: '1', text: 'level-1-2', icon: 'align-left'},
            // {key: '1-3', parentKey: '1', text: 'level-1-3', icon: 'align-left'},
            // {key: '1-4', parentKey: '1', text: 'level-1-4', icon: 'align-left'},
            // {key: '1-4-1', parentKey: '1-4', text: 'level-1-4-1', icon: 'align-left'},
            // {key: '1-4-2', parentKey: '1-4', text: 'level-1-4-2', icon: 'align-left'},
            // {key: '1-4-3', parentKey: '1-4', text: 'level-1-4-3', icon: 'align-left'},
            // {key: '1-4-4', parentKey: '1-4', text: 'level-1-4-4', icon: 'align-left'},
            // {key: '1-4-3-1', parentKey: '1-4-3', text: 'level-1-4-3-1', icon: 'align-left'},
            // {key: '1-4-3-2', parentKey: '1-4-3', text: 'level-1-4-3-2', icon: 'align-left'},
            // {key: '1-4-3-3', parentKey: '1-4-3', text: 'level-1-4-3-3', icon: 'align-left'},
            // {key: '1-4-3-4', parentKey: '1-4-3', text: 'level-1-4-3-4', icon: 'align-left'},

            // {key: 'antDesign', text: 'Ant Design 官网', icon: 'ant-design', url: 'https://ant-design.gitee.io', target: '', order: 2000},
            // {key: 'document', text: '文档', icon: 'book', url: 'https://open.vbill.cn/react-admin', target: '_blank', order: 1200},

            // {key: 'ajax', text: 'ajax请求', icon: 'api', path: '/example/ajax', order: 998},
            // {key: 'customer-header', text: '自定义头部', icon: 'api', path: '/example/customer-header', order: 998},
            // {key: 'user', text: '用户列表', icon: 'user', path: '/users', order: 900},
            // {key: 'roles', text: '角色管理', icon: 'user', path: '/roles', order: 901},
            // {key: 'page404', text: '404页面不存在', icon: 'file-search', path: '/404', order: 700},
            // begin ====================
            { key: 'ArticleStatistics', local: 'ArticleStatistics', text: '点击/分享数据', icon: 'bars', path: '/ArticleStatistics', order: 2101 },

            //文章库
            { key: 'ArticleLib', local: 'ArticleLib', text: '文章库', icon: 'bars', path: '/ArticleLib', order: 2100 },
            //文章详情轮播图
            { key: 'detailSet', local: 'detailSet', text: '文章详情轮播图', icon: 'bars', path: '/detailSet', order: 2002 },
            //左右广告图
            { key: 'aroundAd', local: 'aroundAd', text: '左右广告图配置', icon: 'bars', path: '/aroundAd', order: 2001 },
            //即时新闻
            { key: 'InstantNews', local: 'InstantNews', text: '即时新闻', icon: 'bars', path: '/InstantNews', order: 2000 },

            //首页
            { key: 'HomePage', local: 'HomePage', text: '网站首页', icon: 'bars', order: 1999 },
            { key: 'HomePageChild', parentKey: 'HomePage', text: '子栏目', icon: 'bars', path: '/HomePageChild', order: 1998 },
            { key: 'HomePageArticle', parentKey: 'HomePage', text: '文章列表', icon: 'bars', order: 1997 },

            { key: 'HomePageArticlePublished', parentKey: 'HomePageArticle', text: '已发布文章', icon: 'bars', path: '/HomePageArticlePublished', order: 1989 },
            { key: 'HomePageArticleWaiting', parentKey: 'HomePageArticle', text: '待审核文章', icon: 'bars', path: '/HomePageArticleWaiting', order: 1988 },

            { key: 'HomePageSwipe', parentKey: 'HomePage', text: '轮播图配置', icon: 'bars', path: '/HomePageSwipe', order: 1996 },
            { key: 'FriendlyLink', parentKey: 'HomePage', text: '友情链接', icon: 'bars', path: '/FriendlyLink', order: 1995 },

            //政策解码
            { key: 'ZCdecode', local: 'ZCdecode', text: '政策解码', icon: 'bars', order: 1899 },
            { key: 'ZCdecodeChild', parentKey: 'ZCdecode', text: '子栏目', icon: 'bars', path: '/ZCdecodeChild', order: 1898 },
            { key: 'ZCdecodeArticle', parentKey: 'ZCdecode', text: '文章列表', icon: 'bars', order: 1897 },

            { key: 'ZCdecodeArticlePublished', parentKey: 'ZCdecodeArticle', text: '已发布文章', icon: 'bars', path: '/ZCdecodeArticlePublished', order: 1889 },
            { key: 'ZCdecodeArticleWaiting', parentKey: 'ZCdecodeArticle', text: '待审核文章', icon: 'bars', path: '/ZCdecodeArticleWaiting', order: 1888 },

            { key: 'ZCdecodeSwipe', parentKey: 'ZCdecode', text: '轮播图配置', icon: 'bars', path: '/ZCdecodeSwipe', order: 1896 },
            
            //民航新闻
            { key: 'MHNews', local: 'MHNews', text: '民航新闻', icon: 'bars', order: 1899 },
            { key: 'MHNewsChild', parentKey: 'MHNews', text: '子栏目', icon: 'bars', path: '/MHNewsChild', order: 1898 },
            { key: 'MHNewsArticle', parentKey: 'MHNews', text: '文章列表', icon: 'bars', order: 1897 },

            { key: 'MHNewsArticlePublished', parentKey: 'MHNewsArticle', text: '已发布文章', icon: 'bars', path: '/MHNewsArticlePublished', order: 1889 },
            { key: 'MHNewsArticleWaiting', parentKey: 'MHNewsArticle', text: '待审核文章', icon: 'bars', path: '/MHNewsArticleWaiting', order: 1888 },

            { key: 'MHNewsSwipe', parentKey: 'MHNews', text: '轮播图配置', icon: 'bars', path: '/MHNewsSwipe', order: 1896 },
            //智慧安保
            { key: 'SmartSecurity', local: 'SmartSecurity', text: '智慧安保', icon: 'bars', order: 1799 },
            { key: 'SmartSecurityChild', parentKey: 'SmartSecurity', text: '子栏目', icon: 'bars', path: '/SmartSecurityChild', order: 1798 },
            { key: 'SmartSecurityArticle', parentKey: 'SmartSecurity', text: '文章列表', icon: 'bars', order: 1797 },

            { key: 'SmartSecurityArticlePublished', parentKey: 'SmartSecurityArticle', text: '已发布文章', icon: 'bars', path: '/SmartSecurityArticlePublished', order: 1789 },
            { key: 'SmartSecurityArticleWaiting', parentKey: 'SmartSecurityArticle', text: '待审核文章', icon: 'bars', path: '/SmartSecurityArticleWaiting', order: 1788 },

            { key: 'SmartSecuritySwipe', parentKey: 'SmartSecurity', text: '轮播图配置', icon: 'bars', path: '/SmartSecuritySwipe', order: 1796 },

            //消防应急救援
            { key: 'EmergencyRescue', local: 'SmartSecurity', text: '消防应急救援', icon: 'bars', order: 1705 },
            { key: 'EmergencyRescueChild', parentKey: 'EmergencyRescue', text: '子栏目', icon: 'bars', path: '/EmergencyRescueChild', order: 1704 },
            { key: 'EmergencyRescueticle', parentKey: 'EmergencyRescue', text: '文章列表', icon: 'bars', order: 1703 },

            { key: 'EmergencyRescueArticlePublished', parentKey: 'EmergencyRescueticle', text: '已发布文章', icon: 'bars', path: '/EmergencyRescueArticlePublished', order: 1702 },
            { key: 'EmergencyRescueArticleWaiting', parentKey: 'EmergencyRescueticle', text: '待审核文章', icon: 'bars', path: '/EmergencyRescueArticleWaiting', order: 1701 },

            { key: 'EmergencyRescueSwipe', parentKey: 'EmergencyRescue', text: '轮播图配置', icon: 'bars', path: '/EmergencyRescueSwipe', order: 1700 },


            //专家专栏
            { key: 'ExpertColumn', local: 'ExpertColumn', text: '专家专栏', icon: 'bars', order: 1699 },
            { key: 'ExpertColumnChild', parentKey: 'ExpertColumn', text: '子栏目', icon: 'bars', path: '/ExpertColumnChild', order: 1698 },
            { key: 'ExpertColumnArticle', parentKey: 'ExpertColumn', text: '文章列表', icon: 'bars', order: 1697 },

            { key: 'ExpertTypeList', parentKey: 'ExpertColumn', text: '专家分类管理', icon: 'bars', path: '/ExpertTypeList', order: 1699 },

            { key: 'course', parentKey: 'ExpertColumn', text: '课程管理', icon: 'bars', order: 900 },
            { key: 'AddCourse', parentKey: 'course', text: '添加课程', icon: 'bars', path: '/AddCourse', order: 899 },
            { key: 'CourseList', parentKey: 'course', text: '课程目录', icon: 'bars', path: '/CourseList', order: 898 },


            { key: 'ExpertColumnArticlePublished', parentKey: 'ExpertColumnArticle', text: '已发布文章', icon: 'bars', path: '/ExpertColumnArticlePublished', order: 1689 },
            { key: 'ExpertColumnArticleWaiting', parentKey: 'ExpertColumnArticle', text: '待审核文章', icon: 'bars', path: '/ExpertColumnArticleWaiting', order: 1688 },

            { key: 'ExpertColumnSwipe', parentKey: 'ExpertColumn', text: '轮播图配置', icon: 'bars', path: '/ExpertColumnSwipe', order: 1695 },

            //展会频道
            { key: 'TradeShows', local: 'TradeShows', text: '展会频道', icon: 'bars', order: 1599 },
            { key: 'TradeShowsChild', parentKey: 'TradeShows', text: '子栏目', icon: 'bars', path: '/TradeShowsChild', order: 1598 },
            { key: 'TradeShowsArticle', parentKey: 'TradeShows', text: '文章列表', icon: 'bars', order: 1597 },

            { key: 'TradeShowsArticlePublished', parentKey: 'TradeShowsArticle', text: '已发布文章', icon: 'bars', path: '/TradeShowsArticlePublished', order: 1589 },
            { key: 'TradeShowsArticleWaiting', parentKey: 'TradeShowsArticle', text: '待审核文章', icon: 'bars', path: '/TradeShowsArticleWaiting', order: 1588 },

            { key: 'TradeShowsSwipe', parentKey: 'TradeShows', text: '轮播图配置', icon: 'bars', path: '/TradeShowsSwipe', order: 1596 },
            // {key: 'HomePageBusiness', parentKey: 'TradeShows', text: '展商列表', icon: 'bars', path: '/' ,order: 1595},
            // {key: 'HomePageSpecial', parentKey: 'TradeShows', text: '专题列表', icon: 'bars', path: '/' ,order: 1594},

            // 会员中心
            { key: 'MemberChannel', local: 'MemberChannel', text: '会员中心', icon: 'bars', order: 1499 },
            { key: 'MemberChannelChild', parentKey: 'MemberChannel', text: '子栏目', icon: 'bars', path: '/MemberChannelChild', order: 1498 },
            // {key: 'MemberChannelMan', parentKey: 'MemberChannel', text: '会员列表', icon: 'bars', path: '/' ,order: 1497},
            { key: 'MemberChannelArticle', parentKey: 'MemberChannel', text: '会员活动', icon: 'bars', order: 1496 },

            { key: 'MemberChannelArticlePublished', parentKey: 'MemberChannelArticle', text: '已发布文章', icon: 'bars', path: '/MemberChannelArticlePublished', order: 1489 },
            { key: 'MemberChannelArticleWaiting', parentKey: 'MemberChannelArticle', text: '待审核文章', icon: 'bars', path: '/MemberChannelArticleWaiting', order: 1488 },

            { key: 'MemberChannelSwipe', parentKey: 'MemberChannel', text: '轮播图配置', icon: 'bars', path: '/MemberChannelSwipe', order: 1495 },

            { key: 'MemberType', parentKey: 'MemberChannel', text: '会员类型配置', icon: 'bars', path: '/MemberType', order: 1494 },
            { key: 'VipActivity', parentKey: 'MemberChannel', text: 'vip会员权限图片配置', icon: 'bars', path: '/VipActivity', order: 1493 },

            { key: 'subjectmanager', local: 'subjectmanager', text: '专题模板', icon: 'bars', order: 1399 },
            { key: 'MHNewsSubject', parentKey: 'subjectmanager', text: '民航新闻专题', icon: 'bars', order: 1389 },
            { key: 'MHSubjectList', parentKey: 'MHNewsSubject', text: '专题列表', icon: 'bars', path: '/MHSubjectList', order: 1388 },
            { key: 'MHNewsSubjectArticle', parentKey: 'MHNewsSubject', text: '文章列表', icon: 'bars', order: 1386 },
            { key: 'MHNewsSubjectSwipe2', parentKey: 'MHNewsSubject', text: '轮播图配置', icon: 'bars', path: '/MHNewsSubjectSwipe2', order: 1385 },
            { key: 'MHNewsSubjectArticlePublished', parentKey: 'MHNewsSubjectArticle', text: '已发布文章', icon: 'bars', path: '/MHNewsSubjectArticlePublished', order: 1384 },
            { key: 'MHNewsSubjectArticleWaiting', parentKey: 'MHNewsSubjectArticle', text: '待审核文章', icon: 'bars', path: '/MHNewsSubjectArticleWaiting', order: 1383 },


            { key: 'SecuritySubject', parentKey: 'subjectmanager', text: '展会频道专题', icon: 'bars', order: 1379 },
            { key: 'SecuritySubjectList', parentKey: 'SecuritySubject', text: '专题列表', icon: 'bars', path: '/SecuritySubjectList', order: 1378 },
            { key: 'SecuritySubjectArticle', parentKey: 'SecuritySubject', text: '文章列表', icon: 'bars', order: 1376 },
            { key: 'SecuritySubjectSwipe', parentKey: 'SecuritySubject', text: '轮播图配置', icon: 'bars', path: '/SecuritySubjectSwipe', order: 1375 },
            { key: 'SecuritySubjectArticlePublished', parentKey: 'SecuritySubjectArticle', text: '已发布文章', icon: 'bars', path: '/SecuritySubjectArticlePublished', order: 1374 },
            { key: 'SecuritySubjectArticleWaiting', parentKey: 'SecuritySubjectArticle', text: '待审核文章', icon: 'bars', path: '/SecuritySubjectArticleWaiting', order: 1373 },

            { key: 'UserManager', local: 'UserManager', text: '用户管理', icon: 'bars', order: 1299 },
            { key: 'UserList', parentKey: 'UserManager', text: '普通会员', icon: 'bars', path: '/UserList', order: 1298 },
            { key: 'VipList', parentKey: 'UserManager', text: 'Vip会员', icon: 'bars', path: '/VipList', order: 1297 },
            // {key: 'WaitingMember', parentKey: 'UserManager', text: '待审核行业会员', icon: 'bars', path: '/WaitingMember' ,order: 1398},
            { key: 'ExpertsList', parentKey: 'UserManager', text: '专家列表', icon: 'bars', path: '/ExpertsList', order: 1296 },

            // {key: 'Article', local: 'Article', text: '文章管理', icon: 'bars', order: 1200},
            // {key: 'ArticleLib', parentKey: 'Article', text: '文章库', icon: 'bars' ,  path: '/ArticleLib' , order: 12001 },
            // {key: 'ArticleWatingAudit', parentKey: 'Article', text: '发布待审核', icon: 'bars' ,  path: '/ArticleWatingAudit' , order: 12000 },
            // {key: 'PublishedArticleList', parentKey: 'Article', text: '已发布', icon: 'bars' ,  path: '/PublishedArticleList' , order: 11999 },

            // {key: 'conf', local: 'conf', text: '配置管理', icon: 'bars', order: 1000},
            // {key: 'TitleBar', parentKey: 'conf', text: '标题栏配置', icon: 'bars' ,  path: '/TitleBar' , order: 1001 },
            // {key: 'SwipeAdvertise', parentKey: 'conf', text: '轮播广告配置', icon: 'bars' ,  path: '/SwipeAdvertise' , order: 1001 },


            { key: 'billmanager', local: 'billmanager', text: '账单管理', icon: 'bars', order: 800 },
            { key: 'CourseBill', parentKey: 'billmanager', text: '课程购买账单', icon: 'bars', path: '/CourseBill', order: 799 },
            { key: 'VipBill', parentKey: 'billmanager', text: 'VIP购买账单', icon: 'bars', path: '/VipBill', order: 798 },

            { key: 'report', local: 'report', text: '报名管理', icon: 'bars', path: '/ReportList', order: 700 },

            { key: 'AdvertiseService', text: '广告服务', icon: 'bars', order: 600 },
            { key: 'ServiceSwipe', parentKey: 'AdvertiseService', text: '轮播广告配置', icon: 'bars', path: '/ServiceSwipe', order: 399 },
            { key: 'NewActivities', parentKey: 'AdvertiseService', text: '最新活动', icon: 'bars', path: '/NewActivities', order: 399 },
            { key: 'NewDynamic', parentKey: 'AdvertiseService', text: '最新动态', icon: 'bars', order: 399 },
            { key: 'NewDynamicPublished', parentKey: 'NewDynamic', text: '已发布最新动态', icon: 'bars', path: '/NewDynamicPublished', order: 398 },
            { key: 'NewDynamicWaiting', parentKey: 'NewDynamic', text: '待审核最新动态', icon: 'bars', path: '/NewDynamicWaiting', order: 397 },
            { key: 'ServiceContactUs', parentKey: 'AdvertiseService', text: '联系我们', icon: 'bars', order: 396 },
            { key: 'ServiceContactUsPublished', parentKey: 'ServiceContactUs', text: '已发布联系我们', icon: 'bars', path: '/ServiceContactUsPublished', order: 395 },
            { key: 'ServiceContactUsWaiting', parentKey: 'ServiceContactUs', text: '待审核联系我们', icon: 'bars', path: '/ServiceContactUsWaiting', order: 394 },

            { key: 'ContactUs', text: '联系我们', icon: 'bars', order: 400 },

            { key: 'ContactUsPublished', parentKey: 'ContactUs', text: '已发布', icon: 'bars', path: '/ContactUsPublished', order: 399 },
            { key: 'ContactUsWaiting', parentKey: 'ContactUs', text: '待审核', icon: 'bars', path: '/ContactUsWaiting', order: 398 },

            { key: 'AboutUs', text: '关于我们', icon: 'bars', path: '/AboutUs', order: 300 },

            { key: 'adminnamager', text: '后台权限管理', icon: 'bars', order: 200 },
            { key: 'AdminList', parentKey: 'adminnamager', text: '后台用户列表', icon: 'bars', path: '/AdminList', order: 199 },
            { key: 'RoleList', parentKey: 'adminnamager', text: '角色列表', icon: 'bars', path: '/RoleList', order: 198 },

            { key: 'messagesetting', text: '系统消息设置', icon: 'bars', order: 100 },
            { key: 'messagemanager', parentKey: 'messagesetting', text: '消息管理', icon: 'bars', path: '/MessageList', order: 99 },
            { key: 'noticemanager', parentKey: 'messagesetting', text: '通知管理', icon: 'bars', path: '/NoticeList', order: 98 },

            { key: 'exhibitionSetting', text: '展会管理', icon: 'bars', order: 50 },
            { key: 'exhibitionComplex', parentKey: 'exhibitionSetting', text: '大会综合配置', icon: 'bars', path: '/exhibitionComplex', order: 49 },
            { key: 'exhibitionComplex2021', parentKey: 'exhibitionSetting', text: '大会综合配置2021', icon: 'bars', path: '/exhibitionComplex2', order: 50 },
            { key: 'exhibitor', parentKey: 'exhibitionSetting', text: '展商管理', icon: 'bars', path: '/exhibitor', order: 48 },
            { key: 'setFirmTab', local: 'setFirmTab', text: '综合配置', icon: 'bars', path: '/setFirmTab', order: 30 },
            { key: 'clock', local: 'clock', text: '签到信息', icon: 'bars', path: '/Clock' },
        ]);
    }
}
