import React, { Component } from 'react';
import { Upload, Button, Icon, notification } from 'antd';
import { sxAjax } from '@/commons/ajax';
import { base } from '@/commons/base';
import PropTypes from 'prop-types';
import { imgPreview } from '@/library/utils/image-utils.js';
import MD5 from 'crypto-js/md5';

/**
 * 上传图片到腾讯云cos
 */
const config = {
    Bucket: base.TXCos.Bucket,
    Region: base.TXCos.Region,
};
const COS = require('cos-js-sdk-v5');
const getAuthorization = function (options, callback) {
    // 服务端 JS 和 PHP 例子：https://github.com/tencentyun/cos-js-sdk-v5/blob/master/server/
    // 服务端其他语言参考 COS STS SDK ：https://github.com/tencentyun/qcloud-cos-sts-sdk
    // STS 详细文档指引看：https://cloud.tencent.com/document/product/436/14048
    sxAjax.post('/api/v1/get_tx_cos_sts_token', {}).then((res) => {
        if (res.status === 200) {
            callback({
                TmpSecretId: res.data.Credentials.TmpSecretId,
                TmpSecretKey: res.data.Credentials.TmpSecretKey,
                XCosSecurityToken: res.data.Credentials.Token,
                ExpiredTime: res.data.ExpiredTime, // SDK 在 ExpiredTime 时间前，不会再次调用 getAuthorization
            });
        } else {
            notification.error({
                message: '提示！',
                description: res.msg,
            });
        };
    }).catch(() => {
        notification.error({
            message: '提示！',
            description: "网络超时！",
        });

    });
};
const cos = new COS({
    getAuthorization,
});

export default class TXCOSUpload extends Component {
    constructor(props) {
        super(props);
        if (this.props.onUploadRef) {
            this.props.onUploadRef(this)
        }
    }
    static propTypes = {
        UploadCallBack: PropTypes.func,
        folder: PropTypes.string,
    };

    static defaultProps = {
        folder: '',
    }

    state = { cos: null, ImgFileState: false, update_file: null, file_name: '', file_list: [] };

    componentDidMount() {
        console.log("图片初始化")

    }

    // 检查图片压缩等工作是否准备完毕 生成图片名称
    CheckBeforeUploadingPictures() {
        console.log("子组件方法被调用")
        if (this.state.ImgFileState) {
            notification.warning({
                message: '提示！',
                description: "图片正在压缩中，请稍后提交！",
            });
            return false;
        }

        if (this.state.file_list.length === 0) {
            notification.warning({
                message: '提示！',
                description: "请选择上传图片！",
            });
            return false;
        }

        let file = this.state.file
        let filename = MD5(file.name + file.uid);
        let fileType = file.name.split(".");
        fileType = `.${fileType[fileType.length - 1]}`;
        filename += fileType;
        const ImgkeyName = `${this.props.folder}/${filename}`;
        this.setState({ file_name: ImgkeyName })

        return ImgkeyName
    }

    // 用户选取图片 将图片进行压缩
    onChange(e) {
        
        if (e.fileList.length < this.state.file_list.length) {
            // 删除图片
            this.setState({ file_list: e.fileList })
            return
        }
        let file = e.file
        this.setState({ file: file, ImgFileState: true, file_list: e.fileList })
        imgPreview(file, (file) => {
            this.setState({ update_file: file, ImgFileState: false })
        })
        
    }

    // 自定义上传图片方法
    PutCosImg = () => {

        if (this.state.ImgFileState) {
            notification.warning({
                message: '提示！',
                description: "图片正在压缩中，请稍后提交！",
            });
            return;
        }
        const _this = this

        cos.putObject({
            Bucket: config.Bucket, // Bucket 格式：test-1250000000
            Region: config.Region,
            Key: this.state.file_name,
            Body: this.state.update_file,
        }, function (err, data) {
            _this.props.UploadCallBack(err, data)
            if (err) {
                notification.warning({
                    message: '提示！',
                    description: "图片上传失败。", err,
                });
                return;
            }
            if (data.statusCode !== 200) {
                notification.warning({
                    message: '提示！',
                    description: "图片上传失败[1]。", err,
                });
                return;
            }
        });
    }
    
    render() {
        const { limit , text } = this.props ;
        return (
            <div>

                <Upload {...this.props} onChange={this.onChange.bind(this)} beforeUpload={() => false} >
                    {
                        limit > this.state.file_list ?
                            <Button  >
                                <Icon type="upload" /> {text}
                        </Button> : null
                    }

                </Upload>


                {/* <Button onClick={this.PutCosImg.bind(this)} >
                    <Icon type="upload" /> 上传
            </Button> */}
            </div>
        );
    }
}
