import { sxAjax } from '@/commons/ajax';
import { notification } from 'antd';
export default {
    initialState: {
        article: {},    // 文章数据
        TitleBar: [],   // 标题栏数据
    },
    syncStorage: true, // 全部同步到localStorage中
    setArticleItem: (article) => ({ article }),
    setTitleBarList: (TitleBar) => ({ TitleBar }),

    InitTitleBarList: {
        // 异步action payload 返回promise
        payload: sxAjax.post('/admin_v2/api/query_title_bar', {limit: 100 , offset: 0 }),
        // 基于promise 异步reducer写法；
        reducer: {
            pending: (state, action) => ({ loading: true }),
            resolve(state, { payload = [] } ) {
                const { status = 0, data = {} , msg = '错误' } = payload;
                if (status === 200) {
                    return {
                        TitleBar: data.list,
                    }
                } else {
                    notification.error({
                        message: '查询标题栏失败！',
                        description: msg,
                    });
                }
            },
            complete: (state, action) => ({ loading: false }),
        }
    } // 初始化标题栏数据

}
