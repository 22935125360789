import React, { Component } from 'react';
// Braft Editor 兼容包 在引入BraftEditor之前引入此依赖
import 'braft-polyfill'
//1.组件中引入 braft-editor
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'

import MD5 from 'crypto-js/md5';
import { sxAjax } from '@/commons/ajax';
import { base } from '@/commons/base';
import { notification } from 'antd';

import { matchType } from '@/library/utils/image-utils.js';

/**
 * Braft Editor 富文本编辑器
 */

export default class BraftEditorComponent extends Component {

  state = {
    editorState: BraftEditor.createEditorState(),
    OSSData: {},

  }

  componentDidMount() {
    this.init()
  }

  componentWillReceiveProps(props) {
    // 父组件setState都会更新
    // console.log("子组件更新：",props) 
    if (props.data !== undefined) {
      this.setState({
        editorState: BraftEditor.createEditorState(props.data),
        // OSSData: {},
      })
    }
  }

  //4.表单提交的时候，需要把富文本内容格式转化为html格式的

  //5.由于图片上传、视频上传项目中都是单独走的接口，需要一个上传的方法
  // myUploadFn = (param) => {

  //   console.log('param', param);
  //   const serverURL = 'upload';//upload 是接口地址
  //   const xhr = new XMLHttpRequest();
  //   const fd = new FormData();

  //   const successFn = (response) => {
  //     // 假设服务端直接返回文件上传后的地址
  //     // 上传成功后调用param.success并传入上传后的文件地址
  //     //console.log('response', response.currentTarget);
  //     //console.log('xhr.responseText', xhr.responseText);
  //     const upLoadObject = JSON.parse(response && response.currentTarget && response.currentTarget.response);
  //     param.success({
  //       url: JSON.parse(xhr.responseText).data.fileUrl,
  //       meta: {
  //         id: upLoadObject && upLoadObject.id,
  //         title: upLoadObject && upLoadObject.fileName,
  //         alt: upLoadObject && upLoadObject.fileName,
  //         loop: false, // 指定音视频是否循环播放
  //         autoPlay: false, // 指定音视频是否自动播放
  //         controls: false, // 指定音视频是否显示控制栏
  //         poster: '', // 指定视频播放器的封面
  //       }
  //     })
  //   };

  //   const progressFn = (event) => {
  //     // 上传进度发生变化时调用param.progress
  //     param.progress(event.loaded / event.total * 100)

  //   };

  //   const errorFn = (response) => {
  //     // 上传发生错误时调用param.error
  //     param.error({
  //       msg: 'unable to upload.'
  //     })
  //   };

  //   xhr.upload.addEventListener("progress", progressFn, false);
  //   xhr.addEventListener("load", successFn, false);
  //   xhr.addEventListener("error", errorFn, false);
  //   xhr.addEventListener("abort", errorFn, false);

  //   fd.append('file', param.file);
  //   xhr.open('POST', serverURL, true);
  //   xhr.send(fd)

  // };

  myUploadFn = (param) => {

    // console.log('param', param);
    const file = param.file;

    const { OSSData } = this.state;
    // 文件名
    const suffix = file.name.slice(file.name.lastIndexOf('.'));
    const filename = MD5(Date.now() + file.name + file.uid) + suffix;
    let props_dir = ''
    if (this.props.dir && this.props.dir !== undefined) {
      props_dir = this.props.dir
    }
    file.url = OSSData.dir + props_dir + filename;

    const file_type = matchType(file.name)
    switch (file_type) {
      case 'video':
        // 传给上级 
        if (this.props.GetMediaUrl !== undefined) {
          this.props.GetMediaUrl(file_type,file.url)
        }
        
        break

      case 'image':
         // 传给上级 
         if (this.props.GetMediaUrl !== undefined) {
          this.props.GetMediaUrl(file_type,file.url)
        }

        break

      default:
        notification.error({
          message: '上传失败',
          description: "不支持的文件格式！",
        });
        break
    }

    let formData = new FormData();
    formData.append('OSSAccessKeyId', OSSData.accessid);
    // formData.append('callback', OSSData.callback);
    formData.append('key', file.url);
    formData.append('name', filename);
    formData.append('policy', OSSData.policy);
    formData.append('signature', OSSData.signature);
    formData.append('success_action_status', 200);
    formData.append('file', file);

    const successFn = (su_params) => {
      // 假设服务端直接返回文件上传后的地址
      // 上传成功后调用param.success并传入上传后的文件地址
      //console.log('response', response.currentTarget);
      //console.log('xhr.responseText', xhr.responseText);
      const upLoadObject = su_params;
      param.success({
        url: su_params.url,
        meta: {
          id: upLoadObject && upLoadObject.id,
          title: upLoadObject && upLoadObject.url,
          alt: upLoadObject && upLoadObject.url,
          loop: false, // 指定音视频是否循环播放
          autoPlay: true, // 指定音视频是否自动播放
          controls: true, // 指定音视频是否显示控制栏
          poster: '', // 指定视频播放器的封面
        }
      })
    };

    // const progressFn = (event) => {
    //   // 上传进度发生变化时调用param.progress
    //   param.progress(event.loaded / event.total * 100)

    // };

    const errorFn = (response) => {
      // 上传发生错误时调用param.error
      param.error({
        msg: 'unable to upload.'
      })
    };

    // progressFn()
    sxAjax.post(OSSData.host, formData, 'POST', {
      withCredentials:false
    }).then((res) => {
      let _file = file
      _file.url = base.OssAddress + _file.url
      successFn(file)

    }).catch((res) => {
      if (res.length === 0) {
        successFn(file)
      }else{
        errorFn(file)
        notification.error({
          message: '失败！',
          description: "网络超时！",
        });
      }
      
    });

  };

  init = async () => {
    this.GetOSSData(OSSData => {
 
      if (OSSData === null) {
        return
      }
      this.setState({
        OSSData,
      });
    });
  };

  GetOSSData = (callback) => {
    sxAjax.post('/admin_v2/api/get_osstoken', {}).then((res) => {
      if (res.status === 200) {
        // return {
        //     dir: 'user-dir/',
        //     expire: '1577811661',
        //     host: '//www.mocky.io/v2/5cc8019d300000980a055e76',
        //     accessId: 'c2hhb2RhaG9uZw==',
        //     policy: 'eGl4aWhhaGFrdWt1ZGFkYQ==',
        //     signature: 'ZGFob25nc2hhbw==',
        // };
        callback(res.data);
      } else {
        notification.error({
          message: '失败！',
          description: res.msg,
        });
      };
      callback(null)
    }).catch(() => {
      notification.error({
        message: '失败！',
        description: "网络超时！",
      });

    });

  };

  // 上传图片完毕

  // 预览页面
  preview = () => {

    if (window.previewWindow) {
      window.previewWindow.close()
    }

    window.previewWindow = window.open()
    window.previewWindow.document.write(this.buildPreviewHtml())
    window.previewWindow.document.close()

  }

  buildPreviewHtml() {

    return `
          <!Doctype html>
          <html>
            <head>
              <title>Preview Content</title>
              <style>
                html,body{
                  height: 100%;
                  margin: 0;
                  padding: 0;
                  overflow: auto;
                  background-color: #f1f2f3;
                }
                .container{
                  box-sizing: border-box;
                  width: 1000px;
                  max-width: 100%;
                  min-height: 100%;
                  margin: 0 auto;
                  padding: 30px 20px;
                  overflow: hidden;
                  background-color: #fff;
                  border-right: solid 1px #eee;
                  border-left: solid 1px #eee;
                }
                .container img,
                .container audio,
                .container video{
                  max-width: 100%;
                  height: auto;
                }
                .container p{
                  white-space: pre-wrap;
                  min-height: 1em;
                }
                .container pre{
                  padding: 15px;
                  background-color: #f1f1f1;
                  border-radius: 5px;
                }
                .container blockquote{
                  margin: 0;
                  padding: 15px;
                  background-color: #f1f1f1;
                  border-left: 3px solid #d1d1d1;
                }
              </style>
            </head>
            <body>
              <div class="container">${this.state.editorState.toHTML()}</div>
            </body>
          </html>
        `

  }

  // 将数据传回父组件
  handleChange = (editorState) => {
    this.setState({ editorState })
    this.props.BraftEditorOnChange(editorState)
  }

  //6.render函数中 braft-editor的使用（验证必填项）
  render() {

    const { editorState } = this.state

    const extendControls = [
      {
        key: 'custom-button',
        type: 'button',
        text: '预览',
        onClick: this.preview
      }
    ]

    return (
      <div>

        <BraftEditor
          className="my-editor"
          style={{ border: '1px solid #e8e8e8' }}
          extendControls={extendControls}
          onChange={this.handleChange}
          placeholder="请输入正文内容"
          media={{ uploadFn: this.myUploadFn }}
          value={editorState}
        />

      </div>
    );
  }
}
