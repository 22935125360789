import React, {Component} from 'react';
/*//测试服
export const base = {
    OssAddress: "http://mh-oss.mm419.com/",
    OssDownloadAddress: "https://minhang-security.oss-cn-beijing.aliyuncs.com/",
    TXCos: {
        Bucket: 'mall-1256333653',
        Region: 'ap-beijing',
        AccessDomainName: "https://minhang-security.oss-cn-beijing.aliyuncs.com/",
        BannerFolder: "/HttpServer/Shop/Banner",
        StoreInfo: "/Shop/StoreInfo/Header",
    },
};*/
//正式服
export const base = {
    OssAddress: "https://hanganyun.oss-cn-beijing.aliyuncs.com/",
    OssDownloadAddress: "https://hanganyun.oss-cn-beijing.aliyuncs.com/",
    TXCos: {
        Bucket: 'hanganyun',
        Region: 'ap-beijing',
        AccessDomainName: "https://hanganyun.oss-cn-beijing.aliyuncs.com/",
        BannerFolder: "/HttpServer/Shop/Banner",
        StoreInfo: "/Shop/StoreInfo/Header",
    },
};

export default ({propName = 'base'} = {}) => WrappedComponent => {
    class Base extends Component {
        constructor(props) {
            super(props);
            this.base = base
        }

        static displayName = `WithSubscription(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

        componentWillUnmount() {

        }

        render() {
            const injectProps = {
                [propName]: this.base,
            };
            return <WrappedComponent {...injectProps} {...this.props}/>;
        }
    }

    return Base;
};
